<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2022-12-30 09:24:55
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-03-01 15:13:12
 * @FilePath: /com.huizhouyiren.B0805/src/views/index/pages/navbarPages/Personal.vue
 * @Description: 个人中心主页
-->
<template>
    <div class="flex items-center justify-center w-full sales">
        <div class="personal_box">
            <div class="text-left text-gray-500 path sm:my-3">
                <navbar-text :navbarList="navbarList"></navbar-text>
            </div>
            <div class="personal_content">
                <div class="left_box">
                    <div class="pc_show">
                        <div>
                            <div class="category_title sm:text-base sm:font-bold sm:text-left">个人中心</div>
                            <ul>
                                <li v-for="item in personalTabs" :key="item.path" @click="goPage(item.path)"
                                    :class="item.path == path ? 'text-primary' : ''"
                                    class="text-left cursor-pointer hover:text-primary sm:my-3">{{ item.title }}</li>
                            </ul>
                        </div>
                        <div class="sm:mt-6">
                            <div class="text-left category_title sm:text-base sm:font-bold">账号管理</div>
                            <ul>
                                <li v-for="item in accountTabs" :key="item.path" @click="goPage(item.path)"
                                    :class="item.path == path ? 'text-primary' : ''"
                                    class="text-left cursor-pointer hover:text-primary sm:my-3">{{ item.title }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="phone_show">
                        <div class="category_title sm:text-base sm:font-bold sm:text-left">个人中心</div>
                        <div class="flex scroll_box">
                            <ul>
                                <li v-for="item in personalTabs" :key="item.path" @click="goPage(item.path)"
                                    :class="item.path == path ? 'text-primary' : ''"
                                    class="text-left cursor-pointer hover:text-primary sm:my-3">{{ item.title }}</li>
                            </ul>
                            <ul>
                                <li v-for="item in accountTabs" :key="item.path" @click="goPage(item.path)"
                                    :class="item.path == path ? 'text-primary' : ''"
                                    class="text-left cursor-pointer hover:text-primary sm:my-3">{{ item.title }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            personalTabs: [
                {
                    title: "信息总览",
                    path: "/index/personal/message"
                },
                {
                    title: "我的收藏",
                    path: "/index/personal/collection"
                },
                // {
                //     title: "我的积分",
                //     path: "/index/personal/integral"
                // },
                {
                    title: "浏览历史",
                    path: "/index/personal/history"
                },
                {
                    title: "我的服务",
                    path: "/index/personal/serve"
                },
                {
                    title: "我的设备",
                    path: "/index/personal/equipment"
                },
                {
                    title: "保修查询",
                    path: "/index/warranty/qurey"
                },

                // {
                //     title: "真伪查验",
                //     // path: "/index/personal/inspection"
                //     path: "/index/inspection"
                // },
                // {
                //     title: "中奖纪录",
                //     path: "/index/personal/record"
                // }
            ],
            accountTabs: [
                // {
                //     title: "收货地址",
                //     path: "/index/personal/address"
                // },
                {
                    title: "账号安全",
                    path: "/index/personal/account"
                },
            ],
            path: "",
            navbarList: [{ title: '个人中心', path: '' }]
        };
    },
    created() {
        if (this.getLoginStatus) {
            // this.$store.dispatch("aquireUserInfo")
        } else {
            this.$EventBus.$emit("toast", { type: 'error', message: '请先登录' })
            setTimeout(() => {
                this.$router.push({ path: "/index" })
            }, 1000)
        }

    },
    computed: {
        ...mapGetters(['getLoginStatus'])
    },
    watch: {
        "$route": function () {
            this.path = this.$route.fullPath
        }
    },
    methods: {
        goPage(path) {
            this.path = path
            this.$router.push({ path })
        }
    },
};
</script>

<style lang='scss' scoped>
.personal_box {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 var(--margin-base);
    min-height: var(--min-height);
}

@media screen and (max-width: 900px) {
    .path {
        margin-top: 0.625rem;
    }

    .pc_show {
        display: none;
    }

    .phone_show {
        display: block;
    }

    .personal_content {
        display: block;
    }

    .left_box {
        width: 100%;

        .category_title {
            text-align: center;
        }

        ul {
            display: flex;

            li {
                margin-right: 0.375rem;
                white-space: nowrap;
            }
        }
    }

    .right_box {
        width: 100%;
    }

    .scroll_box::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
    }

    .scroll_box {
        scrollbar-width: none;
        /* firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
        overflow-x: scroll;
        margin: 0.375rem 0;
    }
}

@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }

    .phone_show {
        display: none;
    }

    .personal_content {
        display: flex;
    }

    .left_box {
        width: 11.25rem;
    }

    .right_box {
        width: calc(100% - 11.25rem);
    }
}
</style>